import audit from '@/stores/audit'

const en = {
	jira: {
		stop: 'Stop',
		startAll: 'Export all',
		startSelected: 'Export selected',
		exportCybsisMeasuresIntoJira: 'Export Cybsis measures into Jira',
		amountOfMeasuresToExport: 'Amount of measures to export:',
		finishedTask: 'Finished task',
	},
	status: {
		label: 'Status',
		implemented: 'Implemented',
		conditionallyImplemented: 'Conditionally implemented',
		partiallyImplemented: 'Partially implemented',
		notImplemented: 'Not implemented',
		needsAttention: 'Needs attention',
		willNotImplement: 'Will not implement',
		willNotApply: 'Will not apply',
		hover: {
			conditionallyImplemented:
				'This measure is conditionally implemented - one or more rules have "Will not implement"/"Will not apply" status.',
		},
	},
	notComplete: 'Not Complete',
	byModules: 'By modules',
	normal: 'Normal',
	big: 'Big',
	veryBig: 'Very Big',
	businessProcesses: 'Business processes',
	securityNeed: 'Security Need',
	overallStatus: 'Overall Status',
	of: 'of',
	measures: 'Measures',
	name: 'Name',
	active: 'Active',
	notActive: 'Not active',
	price: 'Price',
	comment: 'Comment',
	cancel: 'Cancel',
	create: 'Create',
	editSelected: 'Edit selected',
	details: 'Details',
	history: 'History',
	connections: 'Connections',
	moduleConnections: 'Module connections',
	businessProcessesConnections: 'Business processes connections',
	assetsConnections: 'Assets connections',
	connection: 'Connection',
	add: 'Add',
	edit: 'Edit',
	delete: 'Delete',
	view: 'View',
	save: 'Save',
	chooseConnection: 'Choose connection',
	confidentiality: 'Confidentiality',
	availability: 'Availability',
	integrity: 'Integrity',
	assetName: 'Asset name',
	assetComment: 'Asset comment',
	assetStatus: 'Asset status',
	dataAsset: 'Data asset',
	module: 'Module',
	moduleTitle: 'Module title',
	methodDescription: 'Method description',
	methodComment: 'Method comment',
	reference: 'Reference',
	title: 'Title',
	responsible: 'Responsible',
	priority: 'Priority',
	rules: 'Rules',
	subrule: 'Subrule',
	businessProcessReferences: 'Business process references',
	rule: 'Rule',
	low: 'Low',
	medium: 'Medium',
	high: 'High',
	choose: 'Choose ...',
	editMeasure: 'Edit measure',
	lowShort: 'low',
	mediumShort: 'medium',
	highShort: 'high',
	main: 'Main',
	standard: 'Standard',
	securityNeedMatrix: 'Security Need Matrix',
	modules: 'Modules',
	relatedAssets: 'Related assets',
	applicableMeasures: 'Applicable measures',
	applicationDescription: 'Application/Description',
	assetReference: 'Asset reference',
	vb: 'VB',
	b: 'B',
	n: 'N',
	confirmation: 'Confirmation',
	confirm: 'Confirm',
	accessRights: 'Access rights',
	general: 'General',
	responsibleShort: 'Responsible ...',
	areYouCertainYouDoNotWantToApplyThisMeasure: 'Are you certain you do not want to apply this measure?',
	pleaseWriteExplanationWhyThisMeasureCannotBeApplied: 'Please write a explanation why this measure cannot be applied.',
	code: 'Code',
	description: 'Description',
	relatedModule: 'Related module',
	moduleDescription: 'Module description',
	addExistingMeasure: 'Add existing measure',
	addNewMeasure: 'Add new measure',
	chooseModule: 'Choose module',
	chooseMeasure: 'Choose measure',
	chooseAsset: 'Choose asset',
	measureIsRequired: 'Measure is required',
	assetIsRequired: 'Asset is required',
	moduleIsRequired: 'Module is required',
	pleaseSelectModuleFirst: 'Please select module first.',
	pleaseSelectAssetFirst: 'Please select asset first.',
	h: 'H',
	s: 'S',
	m: 'M',
	core: 'Core',
	areYouCertainYouWantToChangeTheSecurityMethod: 'Are you certain you want to change the security method?',
	thisWillChangeTheListOfMethodsNeededToBeAppliedDependingOnTheMethodSelected:
		'This will change the list of methods needed to be applied depending on the method selected.',
	measuresFound: 'Measures found',
	appliedUserRights: 'Applied user rights',
	none: 'None',
	own: 'Own',
	all: 'All',
	userRights: 'User rights',
	addSubject: 'Add subject',
	moduleInfo: 'Module info',
	editMethod: 'Edit method',
	chooseBusinessProcess: 'Choose business process',
	owner: 'Owner',
	contactResponsible: 'Contact/responsible',
	close: 'Close',
	associations: 'Associations',
	association: 'Association',
	allBusinessProcesses: 'All Business processes',
	commentIsRequiredWithWillNotApplyStatus: "Comment is required with 'Will not apply' status.",
	assetAssociations: 'Asset associations',
	assetResponsibleReferences: 'Asset responsible references',
	noConnectedAssets: 'No connected assets',
	connectAssetToModule: 'Connect a asset to the module',
	associationName: 'Association name',
	methods: 'Methods',
	userHasFullAccess: 'User has full access',
	thisOperationWillOverrideDataForAllTheMethodsSelected: 'This operation will override data for all the methods selected.',
	saveSelectedMethods: 'Save selected methods',
	saveMethods: 'Save methods',
	methodWasAddedManuallyOutsideOfAssociationRules: 'Method was added manually outside of association rules',
	remove: 'Remove',
	areYouCertainYouDoNotWantToRemoveThisMeasure: 'Are you certain you do not want to remove this measure?',
	pleaseSelectBusinessProcessFirst: 'Please select business process first.',
	custom: 'Custom',
	yes: 'Yes',
	no: 'No',
	institutions: 'Institutions',
	currentlyViewing: 'Currently viewing',
	institution: 'Institution',
	dueDate: 'Due date',
	dueDateIn: 'Due date in',
	day: 'day',
	days: 'days',
	overdue: 'Overdue',
	today: 'Today',
	changesDetected: 'Changes detected',
	thereAreUnsavedChangesInTheCurrentlyOpenForm:
		'There are unsaved changes in the currently open form. Are you sure you want to discard these changes?',
	now: 'Now',
	methodEditedSuccessfully: 'Method edited successfully',
	methodsEditedSuccessfully: 'Methods edited successfully',
	customMethodRemovedSuccessfully: 'Custom method removed successfully',
	securityMethodChangedSuccessfully: 'Security method changed successfully',
	upgradeCompletedSuccessfully: 'Upgrade completed successfully',
	onTime: 'On time',
	approaching: 'Approaching',
	critical: 'Critical',
	measuresDueDateStatus: 'Measures due date status',
	measuresApproachingDueDate: 'Tasks approaching due date',
	relatedDataAssets: 'Related data assets',
	date: 'Date',
	hasPersonalData: 'Has Personal Data',
	unspecified: 'Unspecified',
	internalAuditorComment: 'Internal auditor comment',
	auditComment: 'Auditor comment',
	lastInternalAuditorComment: 'Last internal auditor comment',
	lastAuditorComment: 'Last auditor comment',
	addComment: 'Add comment',
	previous: 'Previous',
	next: 'Next',
	last: 'Last',
	first: 'First',
	modal: {
		cancel: 'Cancel',
		confirm: 'Confirm',
	},
	securityMethod: {
		title: 'Security method',
		main: 'Main',
		standard: 'Standard',
		dialog: {
			title: 'Confirmation',
			body1: 'Are you certain you want to change the security method?',
			body2: 'This will change the list of methods needed to be applied depending on the method selected.',
		},
	},
	loseUnsavedChanges: 'Unsaved changes will be lost!',
	massEditWarning: 'NB! This action will overwrite all selected connections.',
	methodWillNotApply: 'Measure will not apply',
	methodImplemented: 'Implement all rules',
	willNotApplyCommentRequired: 'Will not apply reason is required.',
	auditType: 'Audit type',
	noAudit: 'No audit',
	basicAudit: 'Audit',
	internalAudit: 'Internal audit',
	addAuditComment: 'Insert audit comment',
	noAuditComments: 'No comments',
	auditCreated: 'New audit created',
	apiErrors: {
		'audit name': 'Invalid audit name',
		'auditor level': 'Audit permission denied',
	},
}
export default en
