import i18n from '@/i18n'
import {
	ConditionallyImplemented,
	Implemented,
	NeedsAttention,
	NotImplemented,
	PartiallyImplemented,
	RuleConditionallyImplemented,
	RuleImplemented,
	RuleNeedsAttention,
	RuleNotImplemented,
	RuleWillNotApply,
	RuleWillNotImplement,
	WillNotApply,
	WillNotImplement,
	type RuleStatus,
} from '@/models/enums-generated'

export function getMethodStatusText(status: string | undefined) {
	switch (status) {
		case PartiallyImplemented:
			return i18n.global.t('status.partiallyImplemented')
		case Implemented:
			return i18n.global.t('status.implemented')
		case WillNotApply:
			return i18n.global.t('status.willNotApply')
		case NeedsAttention:
			return i18n.global.t('status.needsAttention')
		case NotImplemented:
			return i18n.global.t('status.notImplemented')
		case WillNotImplement:
			return i18n.global.t('status.willNotImplement')
		case ConditionallyImplemented:
			return i18n.global.t('status.conditionallyImplemented')
		default:
			return ''
	}
}

export function getRuleStatusText(status: number | undefined) {
	switch (status) {
		case 0:
			return i18n.global.t('status.notImplemented')
		case 1:
			return i18n.global.t('status.implemented')
		case 2:
			return i18n.global.t('status.willNotImplement')
		case 3:
			return i18n.global.t('status.needsAttention')
		case 4:
			return i18n.global.t('status.willNotApply')
		case 5:
			return i18n.global.t('status.conditionallyImplemented')
		default:
			return ''
	}
}

export const isCommentRequired = (status: RuleStatus, comment: string) =>
	[RuleWillNotApply, RuleWillNotImplement].includes(status) && !comment

export const calculateParentRuleStatus = (subruleStatuses: RuleStatus[]) => {
	const count = {
		implemented: 0,
		partiallyImplemented: 0,
		notImplemented: 0,
		willNotImplement: 0,
		willNotApply: 0,
		needsAttention: 0,
	}

	subruleStatuses.forEach((status) => {
		switch (status) {
			case RuleImplemented:
				count.implemented++
				break
			case RuleNotImplemented:
				count.notImplemented++
				break
			case RuleWillNotApply:
				count.willNotApply++
				break
			case RuleNeedsAttention:
				count.needsAttention++
				break
			case RuleWillNotImplement:
				count.willNotImplement++
				break
		}
	})

	const subrulesLength = subruleStatuses.length

	let status

	switch (true) {
		case count.implemented == subrulesLength:
			status = RuleImplemented
			break
		case count.needsAttention > 0:
			status = RuleNeedsAttention
			break
		case count.implemented > 0 && count.implemented + count.willNotApply + count.willNotImplement == subrulesLength:
			status = RuleConditionallyImplemented
			break
		case count.willNotImplement > 0 && count.willNotImplement + count.willNotApply == subrulesLength:
			status = RuleWillNotImplement
			break
		case count.willNotApply == subrulesLength:
			status = RuleWillNotApply
			break
	}

	return status
}

export function formatDate(iso: string) {
	return new Date(iso).toLocaleDateString(i18n.global.locale.value, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})
}

export function formatDateTime(iso: string) {
	return new Date(iso).toLocaleString(i18n.global.locale.value, {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})
}
