<script setup lang="tsx">
import type { Response, Audit, AuditComment, AddAuditCommentRequest, ListAuditCommentsRequest } from '@/models/api-generated'
import http from '@/services/http'
import { computed, onBeforeMount, ref, watch } from 'vue'
import CommentCard from './CommentCard.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
	methodMetadataID: Number,
})

const i18n = useI18n()
const model = defineModel<Audit>({ required: true })
const comments = ref<AuditComment[]>([])
const canAdd = computed(() => window.sessionMap.auditType === model.value.type)

async function fetchComments() {
	comments.value.splice(0, comments.value.length)

	if (!model.value.id) return

	const req: ListAuditCommentsRequest = {
		auditID: model.value.id ?? 0,
		methodMetadataID: props.methodMetadataID ?? 0,
	}
	const { data } = await http.post<Response<AuditComment[]>>('api/audit/listComments', req)
	if (data.data?.length) comments.value.push(...data.data)
}

async function addComment() {
	const val = prompt(i18n.t('addAuditComment'))
	if (!val) return

	const req: AddAuditCommentRequest = {
		value: val,
		auditID: model.value.id ?? 0,
		methodMetadataID: props.methodMetadataID ?? 0,
	}

	await http.post('api/audit/addComment', req)
	await fetchComments()
}

onBeforeMount(fetchComments)
watch(model, fetchComments)
</script>

<template>
	<CommentCard v-if="comments.length" v-for="(a, i) in comments" v-model="comments[i]" v-model:audit="model"></CommentCard>
	<CommentCard v-else v-model:audit="model"></CommentCard>

	<div class="text-center w-100 m-0 my-3" v-if="model.isActive && canAdd">
		<button class="btn btn-primary btn-sm rounded-pill" @click="addComment()">
			<i class="bi bi-journal-plus fs-0"></i>
		</button>
	</div>
</template>
