<script setup lang="tsx">
import type { Audit, AuditComment } from '@/models/api-generated'
import { formatDateTime } from '@/services/utils'

const model = defineModel<AuditComment>()
const audit = defineModel<Audit>('audit', { required: true })
</script>

<template>
	<div class="card w-100 m-2">
		<div class="card-body p-0" v-if="model">
			<h5 class="card-title text-center m-0 mb-3">{{ audit.name }}</h5>

			<div class="d-inline-flex justify-content-between w-100 m-0 mb-3">
				<span>{{ formatDateTime(model.createdAt) }}</span>
				<span class="badge bg-secondary position-relative">{{ model.author.label }}</span>
			</div>

			<p class="card-text">{{ model.value }}</p>
		</div>
		<div class="card-body p-0" v-else>
			<h5 class="card-title text-center m-0 mb-3">{{ audit.name }}</h5>
			<p class="card-text text-center">{{ $t('noAuditComments') }}</p>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.card
    background-color: #eee
h5, span
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>
