<script setup lang="tsx">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
	RuleNotImplemented,
	RuleImplemented,
	RuleWillNotApply,
	RuleNeedsAttention,
	RuleWillNotImplement,
	RuleConditionallyImplemented,
} from '@/models/enums-generated'

const props = defineProps({
	id: String,
	disabled: Boolean,
	hasSubrules: { type: Boolean, default: false },
})
const model = defineModel({ default: RuleNotImplemented })

const isParentChangeEvent = defineModel('isParentChangeEvent', { default: false })

const { t } = useI18n()

const onSelect = () => {
	if (props.hasSubrules) {
		isParentChangeEvent.value = true
	}
}

const statuses = [
	{
		value: RuleNotImplemented,
		text: t('status.notImplemented'),
	},
	{
		value: RuleImplemented,
		text: t('status.implemented'),
	},
	{
		value: RuleWillNotImplement,
		text: t('status.willNotImplement'),
	},
	{
		value: RuleNeedsAttention,
		text: t('status.needsAttention'),
	},
	{
		value: RuleWillNotApply,
		text: t('status.willNotApply'),
	},
	{
		value: RuleConditionallyImplemented,
		text: t('status.conditionallyImplemented'),
	},
]

const filteredStatuses = computed(() =>
	props.hasSubrules ? statuses : statuses.filter((status) => status.value !== RuleConditionallyImplemented),
)
</script>

<template>
	<select :id="props.id" :disabled="props.disabled" class="form-select" v-model="model" @change="onSelect">
		<option :disabled="status.value === RuleConditionallyImplemented" v-for="status in filteredStatuses" :value="status.value">
			{{ status.text }}
		</option>
	</select>
</template>
