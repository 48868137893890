// Code generated by tygo. DO NOT EDIT.

//////////
// source: audit.go

export type AuditType = number /* int */;
export const BasicAudit: AuditType = 0 + 1;
export const InternalAudit: AuditType = 1 + 1;

//////////
// source: priority.go

export type Priority = string;
export const HighPriority: Priority = "high";
export const MediumPriority: Priority = "medium";
export const LowPriority: Priority = "low";

//////////
// source: status.go

export type RuleStatus = number /* int */;
export const RuleNotImplemented: RuleStatus = 0;
export const RuleImplemented: RuleStatus = 1;
export const RuleWillNotImplement: RuleStatus = 2;
export const RuleNeedsAttention: RuleStatus = 3;
export const RuleWillNotApply: RuleStatus = 4;
export const RuleConditionallyImplemented: RuleStatus = 5;
export type MethodStatus = string;
export const NotImplemented: MethodStatus = "Not implemented";
export const PartiallyImplemented: MethodStatus = "Partially implemented";
export const Implemented: MethodStatus = "Implemented";
export const ConditionallyImplemented: MethodStatus = "Conditionally implemented";
export const WillNotImplement: MethodStatus = "Will not implement";
export const WillNotApply: MethodStatus = "Will not apply";
export const NeedsAttention: MethodStatus = "Needs attention";
