<script setup lang="tsx">
import { ref, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import http from '@/services/http'
import RuleRow from '@/components/rules/RuleRow.vue'

import type {
	Response,
	ApplicableMethodInfo,
	StandardRuleMetaData,
	CustomRuleMetaData,
	ApplicableMethodMetaData,
} from '@/models/api-generated'
import methodEditor from '@/stores/method-editor'
import { RuleImplemented, RuleWillNotApply, RuleWillNotImplement, type RuleStatus } from '@/models/enums-generated'
import { PromptForRequiredComment } from '../methods/required-comment'

const props = defineProps<{
	//methodId: number
	isCustom: boolean
}>()

const model = defineModel<ApplicableMethodMetaData>({ required: true })
const info = ref<ApplicableMethodInfo<any>>()
const editor = methodEditor()
const titles = ref(['internalAuditorComment', 'auditComment', 'lastInternalAuditorComment', 'lastAuditorComment'])

const { t } = useI18n()

const DANGER_BUTTON_STYLE = 'btn-danger me-2'
const SUCCESS_BUTTON_STYLE = 'btn-success'

const buttons = [
	{
		status: RuleWillNotImplement,
		label: t('status.willNotImplement'),
		style: DANGER_BUTTON_STYLE,
	},
	{
		status: RuleWillNotApply,
		label: t('status.willNotApply'),
		style: DANGER_BUTTON_STYLE,
	},
	{
		status: RuleImplemented,
		label: t('status.implemented'),
		style: SUCCESS_BUTTON_STYLE,
	},
]

function setStatus(meta: ApplicableMethodMetaData, rules: (StandardRuleMetaData | CustomRuleMetaData)[], status: RuleStatus) {
	let cmt: string | null | undefined
	if ([RuleWillNotApply, RuleWillNotImplement].includes(status)) {
		cmt = PromptForRequiredComment()
		meta.comment = cmt
	}

	for (let i = 0; i < rules.length; i++) {
		rules[i].status = status
		if (cmt) rules[i].comment = cmt

		for (let j = 0; j < rules[i].sub_rules.length; j++) {
			rules[i].sub_rules[j].status = status
			if (cmt) rules[i].sub_rules[j].comment = cmt
		}
	}
}

onBeforeMount(async () => {
	const { data } = await http.get<Response<ApplicableMethodInfo<any>>>(`/api/applicableMethods/${model.value.id}/${props.isCustom}`)
	if (data.data) info.value = data.data
})
</script>

<template>
	<div class="row text-center fw-bold">
		<div class="col">{{ $t('rule') }}</div>
		<div class="col-3">{{ $t('status.label') }}</div>
	</div>
	<template v-if="info">
		<template v-for="(rule, i) in info.meta.rules" :key="rule.id">
			<RuleRow v-model="info.meta.rules[i]"> </RuleRow>

			<div v-if="info.oldRuleMap[rule.id]" class="mt-3 eits-rule-migration">
				<h6 class="mx-2">E-ITS {{ info.oldRuleMap[rule.id].rule.version }}</h6>
				<RuleRow v-model="info.oldRuleMap[rule.id]" :is-read-only="true"></RuleRow>
			</div>
			<hr v-if="i < info.meta.rules.length - 1" />
		</template>

		<div v-if="info.oldRuleAll.length > 0" class="mt-3 eits-rule-migration">
			<h6 class="mx-2">E-ITS {{ info.oldRuleAll[0].rule.version }}</h6>
			<template v-for="(oldRule, i) in info.oldRuleAll" :key="oldRule.id">
				<RuleRow v-model="info.oldRuleAll[i]" :is-read-only="true"> </RuleRow>
				<hr v-if="i < info.oldRuleAll.length - 1" />
			</template>
		</div>

		<div class="m-4" v-if="editor.isEditMode">
			<button v-for="button in buttons" :class="[button.style, 'btn']" @click="setStatus(model, info.meta.rules, button.status)">
				{{ button.label }}
			</button>
		</div>
	</template>
</template>

<style lang="sass" scoped></style>
