<script setup lang="tsx">
import type { Response, Audit, StartAuditRequest } from '@/models/api-generated'
import { BasicAudit, InternalAudit, type AuditType } from '@/models/enums-generated'
import http from '@/services/http'
import { formatDate } from '@/services/utils'
import auditStore from '@/stores/audit'
import toastStore from '@/stores/toast'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const start = ref<StartAuditRequest>({
	name: '',
	isPublic: true,
})

const i18n = useI18n()
const audit = auditStore()
const toaster = toastStore()
const oldSelected = ref<Audit>()

const auditType = computed(() => window.sessionMap.auditType)
const auditTypeStr = (typ: AuditType) => {
	switch (typ) {
		case BasicAudit:
			return i18n.t('basicAudit')
		case InternalAudit:
			return i18n.t('internalAudit')
		default:
			return ''
	}
}
const instanceStr = computed(() => {
	return document.body.querySelector('#view-select option:checked')?.innerHTML
})

onBeforeMount(async () => await audit.fetch())

async function startAudit() {
	const { data } = await http.post<Response<any>>('api/audit/start', start.value)

	if (data.status === 'OK') {
		toaster.showSuccess(i18n.t('auditCreated'))

		start.value.name = ''
		start.value.isPublic = true

		await audit.fetch()
	}
}

async function navigateToAudit(a?: Audit) {
	const params = new URLSearchParams(document.location.search)
	params.set('audit', '')

	if (a && !a.isActive) {
		params.set('audit', a.id.toString())
	}

	document.location.href = 'app/methods?' + params.toString()
}
</script>

<template>
	<div class="card mb-3" v-for="a in audit.active">
		<h5 class="card-header">{{ $t('activeAudit') }}</h5>

		<div class="card-body">
			<div class="form-group row mb-2">
				<label class="col-7 col-form-label">{{ $t('name') }}</label>
				<div class="col-5 text-end">
					{{ a.name }}
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label">{{ $t('created') }}</label>
				<div class="col-5 text-end">
					{{ formatDate(a.createdAt) }}
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label" for="flexSwitchCheckDefault">{{ $t('public') }}</label>

				<div class="col-5 form-check form-switch align-self-center text-end">
					{{ a.isPublic ? $t('yes') : $t('no') }}
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label">{{ $t('auditType') }}</label>
				<div class="col-5 text-end">
					{{ auditTypeStr(a.type) }}
				</div>
			</div>

			<div class="form-group row">
				<div class="col-7"></div>
				<div class="col-5">
					<button class="btn btn-primary w-100" @click="navigateToAudit(a)">{{ $t('view') }}</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card mb-3" v-if="auditType">
		<h5 class="card-header">{{ $t('startNewAudit') }}</h5>

		<div class="card-body">
			<div class="form-group row mb-2">
				<label for="name" class="col-7 col-form-label">{{ $t('name') }}</label>
				<div class="col-5">
					<input type="text" class="form-control" id="name" v-model="start.name" maxlength="20" />
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label" for="isPublic">{{ $t('public') }}</label>

				<div class="col-5 form-check form-switch align-self-center">
					<input
						class="form-check-input"
						type="checkbox"
						role="switch"
						id="isPublic"
						v-model="start.isPublic"
						:disabled="auditType != InternalAudit"
					/>
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label">{{ $t('auditType') }}</label>
				<div class="col-5 text-end">
					{{ auditTypeStr(auditType) }}
				</div>
			</div>

			<div class="form-group row mb-2">
				<label class="col-7 col-form-label">{{ $t('institution') }}</label>
				<div class="col-5 text-end">
					{{ instanceStr }}
				</div>
			</div>

			<div class="form-group row">
				<div class="col-7"></div>
				<div class="col-5">
					<button class="btn btn-primary w-100" @click="startAudit()">{{ $t('newAudit') }}</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card mb-6" v-if="audit.old.length">
		<h5 class="card-header">{{ $t('oldAudits') }}</h5>

		<div class="card-body">
			<div class="form-group row mb-2">
				<label for="old-audit-select" class="col-4 col-form-label">{{ $t('name') }}</label>
				<div class="col-8">
					<select id="old-audit-select" class="form-select" v-model="oldSelected">
						<option v-for="a in audit.old" :value="a">{{ formatDate(a.createdAt) }} - {{ a.name }}</option>
					</select>
				</div>
			</div>

			<div class="row mb-2">
				<div class="col-7"></div>
				<div class="col-5">
					<button class="btn btn-primary w-100" :disabled="!oldSelected" @click="navigateToAudit(oldSelected)">{{ $t('view') }}</button>
				</div>
			</div>

			<div class="row">
				<div class="col-7"></div>
				<div class="col-5">
					<button class="btn btn-primary w-100" :disabled="!oldSelected">{{ $t('export') }}</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card mb-6" v-if="!auditType && !audit.active.length && !audit.old.length">
		<h5 class="card-header">{{ $t('noActiveAuditsHeader') }}</h5>

		<div class="card-body">{{ $t('noActiveAuditsBody') }}</div>
	</div>

	<br />
	<br />
	<br />
</template>
