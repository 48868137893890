<script setup lang="tsx">
import { defineProps, onBeforeMount, onMounted, ref, render, watch, type Component } from 'vue'

import toastStore, { type ToastMessage } from '@/stores/toast'
import * as bootstrap from 'bootstrap'

const props = defineProps({
	errors: Array<String>,
	success: Array<String>,
})

const store = toastStore()

const elementRef = ref<HTMLElement>()

const hmm = (e: ToastMessage) => (
	<div class={'toast toast-' + e.type} role="alert" aria-live="assertive" aria-atomic="true">
		<div class="toast-header">
			<strong class="me-auto">E-ITS</strong>
			<button type="button" class="btn-close btn-close-small" aria-label="Close" data-bs-dismiss="toast"></button>
		</div>
		<div class="toast-body">{e.text}</div>
	</div>
)

onMounted(() => {
	if (props.errors?.length) props.errors.forEach((x) => store.showError(x.valueOf()))
	if (props.success?.length) props.success.forEach((x) => store.showSuccess(x.valueOf()))
})

/*function setRef(el: Element | Component | null, msg: ToastMessage) {
	if (!el) return

	const html = el as HTMLElement
	msg.toast = html

	const toast = new bootstrap.Toast(el as Element, {})

	html.addEventListener('hidden.bs.toast', () => {
		const idx = store.messages.findIndex((x) => x.toast == html)
		if (idx >= 0) store.messages.splice(idx, 1)
	})

	toast.show()
}*/

function cls(type: string) {
	const c = {} as any
	c['toast-' + type] = true
	return c
}

watch(store.messages, (newValue) => {
	if (!newValue?.length || !elementRef.value) return

	for (let msg of newValue) {
		if (!msg.toast) {
			const parent = document.createElement('div')
			parent.classList.add('mb-2')
			elementRef.value.appendChild(parent)

			const vnode = hmm(msg)
			render(vnode, parent)

			const el = vnode.el as Element
			msg.toast = new bootstrap.Toast(el, {})

			el.addEventListener('hidden.bs.toast', () => {
				parent.remove()
				const idx = store.messages.findIndex((x) => x.toast == msg.toast)
				if (idx >= 0) store.messages.splice(idx, 1)
			})

			msg.toast.show()
		}
	}
})
</script>

<template>
	<div ref="elementRef" class="toast-container position-fixed top-0 start-50 translate-middle-x p-3" v-once></div>
</template>
