<script setup lang="tsx">
import { BasicAudit, InternalAudit, type AuditType } from '@/models/enums-generated'
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

const props = defineProps({
	name: String,
	value: String,
})

const options = [
	{
		value: undefined,
		text: () => i18n.t('noAudit'),
	},
	{
		value: BasicAudit,
		text: () => i18n.t('basicAudit'),
	},
	{
		value: InternalAudit,
		text: () => i18n.t('internalAudit'),
	},
]

const model = defineModel<AuditType>()

let idCounter = 0
let label: string

onBeforeMount(() => {
	label = `auditor-level-select-${idCounter++}`

	if (props.value !== undefined) {
		model.value = Number.parseInt(props.value)
	}
})
</script>

<template>
	<label :for="label" class="form-label">{{ $t('auditType') }}</label>
	<select class="form-control form-control-sm" data-type="number" :id="label" :name="props.name" v-model="model">
		<option v-for="opt of options" :value="opt.value">{{ opt.text() }}</option>
	</select>
</template>
