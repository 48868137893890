import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { Response, Audit } from '@/models/api-generated'
import http from '@/services/http'
import { BasicAudit, InternalAudit } from '@/models/enums-generated'

export default defineStore('audit', () => {
	const activeInternal = ref<Audit>()
	const activeBasic = ref<Audit>()

	const oldInternal = ref<Audit[]>([])
	const oldBasic = ref<Audit[]>([])

	const active = computed(() => {
		const a = []
		if (activeInternal.value) a.push(activeInternal.value)
		if (activeBasic.value) a.push(activeBasic.value)
		return a
	})
	const old = computed(() => [...oldInternal.value, ...oldBasic.value])

	const oldInternalSelected = ref<Audit>()
	const oldBasicSelected = ref<Audit>()

	const isAudit = computed(() => new URLSearchParams(document.location.search).has('audit'))

	async function fetch() {
		oldInternal.value.splice(0, oldInternal.value.length)
		oldBasic.value.splice(0, oldBasic.value.length)

		const { data } = await http.get<Response<Audit[]>>('api/audit/list')
		if (data.data?.length) {
			for (const a of data.data) {
				/*if (a.isPublic) {
					if (a.isActive) activePublic.value = a
					else oldPublic.value.push(a)
				} else {
					if (a.isActive) activePrivate.value = a
					else oldPrivate.value.push(a)
				}*/

				if (a.type === BasicAudit) {
					if (a.isActive) activeBasic.value = a
					else oldBasic.value.push(a)
				} else if (a.type === InternalAudit) {
					if (a.isActive) activeInternal.value = a
					else oldInternal.value.push(a)
				}
			}

			const params = new URLSearchParams(document.location.search)
			const auditStr = params.get('audit')

			if (auditStr) {
				const id = Number.parseInt(auditStr)
				const selected = data.data.find((x) => x.id === id)
				if (selected) {
					if (selected.type === BasicAudit) oldBasicSelected.value = selected
					else if (selected.type === InternalAudit) oldInternalSelected.value = selected
				}
			}
		}
	}

	return {
		activeInternal,
		activeBasic,
		oldInternal,
		oldBasic,
		oldInternalSelected,
		oldBasicSelected,
		active,
		old,
		isAudit,
		fetch,
	}
})
