<script setup lang="tsx">
import { ref, onMounted, watch, onBeforeUnmount, defineProps, defineEmits } from 'vue'
import { Modal } from 'bootstrap'

const props = defineProps({
	modalClass: String,
})

const model = defineModel<boolean>({ required: true })
const emit = defineEmits(['confirm', 'cancel'])
const modalRef = ref<HTMLElement | null>(null)

let modalInstance: Modal | null = null
let modalShown = false

onMounted(() => {
	const modalElement = modalRef.value
	if (modalElement) {
		modalInstance = new Modal(modalElement, {
			backdrop: 'static',
		})

		modalElement.addEventListener('shown.bs.modal', () => {
			modalShown = true
			if (!model.value) model.value = true
		})

		modalElement.addEventListener('hidden.bs.modal', () => {
			modalShown = false
			if (model.value) model.value = false
		})

		if (model.value) {
			modalInstance.show()
		}
	}
})

onBeforeUnmount(() => {
	if (modalInstance) {
		modalInstance.dispose()
	}
})

watch(model, (newValue) => {
	if (modalInstance) {
		if (newValue && !modalShown) {
			modalInstance.show()
		} else if (!newValue && modalShown) {
			modalInstance.hide()
		}
	}
})
</script>

<template>
	<div ref="modalRef" class="modal fade" tabindex="-1">
		<div class="modal-dialog" :class="modalClass">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100">
						<slot name="header">Default Title</slot>
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="emit('cancel')"></button>
				</div>
				<div class="modal-body">
					<slot name="body">
						<p>Default Body Content</p>
					</slot>
				</div>
				<div class="modal-footer">
					<slot name="footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="emit('cancel')">
							{{ $t('modal.cancel') }}
						</button>
						<button type="button" class="btn btn-primary" @click="emit('confirm')">{{ $t('modal.confirm') }}</button>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>
