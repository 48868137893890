import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export interface ToastMessage {
	type: 'error' | 'success'
	text: string
	toast?: bootstrap.Toast
}

export default defineStore('toast', () => {
	const messages = ref<ToastMessage[]>([])

	function showSuccess(text: string) {
		messages.value.push({
			type: 'success',
			text: text,
		})
	}

	function showError(text: string) {
		messages.value.push({
			type: 'error',
			text: text,
		})
	}

	function closeOne() {
		if (messages.value.length > 0) {
			messages.value.pop()
		}
	}

	return { showSuccess, showError, closeOne, messages }
})
