import { ref, watch, type Component } from 'vue';
import { defineStore } from 'pinia';
import AuditSlide from '@/components/audit/AuditSlide.vue';

export default defineStore('slider', () => {
	const isVisible = ref(false);
	const title = ref<String>();
	const component = ref<Component>();

	function getComponent() {
		return component.value;
	}

	function openAudit() {
		setTimeout(() => {
			title.value = 'Auditeerimine';
			component.value = <AuditSlide></AuditSlide>;
			isVisible.value = true;
		}, 0);
	}

	return { isVisible, title, getComponent, openAudit };
});
