<script setup lang="tsx">
import store from '@/stores/slider'

const slider = store()
</script>

<template>
	<a
		class="side_nav-link nav-link px-2"
		title=""
		data-bs-toggle="tooltip"
		data-bs-placement="right"
		data-bs-original-title="Audit"
		@mouseup="slider.openAudit()"
	>
		<i class="bi bi-clipboard-check fs-0"></i>
	</a>
</template>
