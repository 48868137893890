import type { ResponseErrors } from '@/models/api-generated'
import toastStore from '@/stores/toast'
import axios from 'axios'
import type { CreateAxiosDefaults, AxiosInterceptorOptions } from 'axios'

import type { Response } from '@/models/api-generated'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'

const config: CreateAxiosDefaults = {}
const http = axios.create(config)
export default http

const options: AxiosInterceptorOptions = {}

export function initHttp() {
	const toaster = toastStore()

	// Implement global error handling
	http.interceptors.response.use(
		function (response) {
			return response
		},
		function (error) {
			if (!error.response) {
				toaster.showError(error.message)
				return Promise.resolve({ data: { data: {} } })
			}

			const err = error.response.data as Response<any>
			if (err.errors) {
				const msg = i18n.global.t('apiErrors.' + err.errors.detail)
				toaster.showError(msg)
			}
			return Promise.resolve(error.response)
		},
		options,
	)
}
