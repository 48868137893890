type CountedTextArea = HTMLTextAreaElement & {
	__counter_div: HTMLElement
	__counter_label: HTMLElement
}

export function initCountedTextareas() {
	document.body.querySelectorAll('textarea').forEach(listenForInput)

	new MutationObserver((mut, _) => {
		mut.forEach((x) => {
			x.addedNodes.forEach((y) => {
				const el = y as Element
				if (el.tagName === 'TEXTAREA') listenForInput(el)
				if (typeof el.querySelectorAll === 'function') el.querySelectorAll('textarea').forEach(listenForInput)
			})
		})
	}).observe(document.body, { childList: true, subtree: true })
}

function listenForInput(text: Element) {
	const el = text as CountedTextArea
	if (el.maxLength < 0) return

	const listener = () => {
		el.removeEventListener('input', listener)
		addCounter(el)
	}
	el.addEventListener('input', listener)
}

function addCounter(text: Element) {
	const el = text as CountedTextArea
	if (el.__counter_div) return

	const label = document.createElement('div')
	el.__counter_label = label
	label.style.position = 'absolute'
	label.style.alignSelf = 'flex-end'
	label.style.textAlign = 'end'
	label.style.width = '100%'
	label.style.fontSize = '.6rem'
	label.style.color = 'darkgray'
	label.style.zIndex = '5'
	label.style.margin = '0'
	label.style.padding = '0 1rem 0 0'
	format(el)
	el.addEventListener('input', () => format(el))

	const div = document.createElement('div')
	el.__counter_div = div
	div.style.pointerEvents = 'none'
	div.style.position = 'relative'
	div.style.display = 'flex'
	div.style.width = '100%'
	div.style.margin = '0'
	div.style.padding = '0'

	div.appendChild(label)
	el.insertAdjacentElement('afterend', div)
}

function format(el: CountedTextArea) {
	el.__counter_label.innerText = `${el.value.length} / ${el.maxLength}`
}
