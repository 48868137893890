<script setup lang="tsx">
import { ref, onBeforeMount, watch, nextTick, computed } from 'vue'
import http from '@/services/http'
import type { ApplicableMethodMetaData, MassChanges, MassEditRequest, Response } from '@/models/api-generated'

import PlusButton from '@/components/widgets/PlusButton.vue'
import Pagination from '@/components/widgets/PaginationComponent.vue'
import type { ApplicableMethodExpand, ApplicableMethodsResponse } from '@/models/applicable-method'

import MassEditMethodDialog from './MassEditMethodDialog.vue'
import methodEditor from '@/stores/method-editor'
import auditStore from '@/stores/audit'
import MethodMetaRow from './MethodMetaRow.vue'
import EditMethodsToolbar from './EditMethodsToolbar.vue'

const editor = methodEditor()
const audit = auditStore()
const model = ref<ApplicableMethodsResponse>()
const currentPage = ref(1)
const isAllExpanded = ref(false)
const showCheckbox = computed(() => !(editor.isEditMode || audit.isAudit))

editor.fetch = async () => await fetchMethods(currentPage.value)

watch(currentPage, async (value) => {
	await fetchMethods(value)
})

watch(isAllExpanded, (value) => {
	for (let a of model.value?.methods ?? []) {
		a.isExpanded = value
	}
})

function checkAllMeta(value: boolean, index: number) {
	if (!model.value) return

	for (let i = 0; i < model.value.methods.length; i++) {
		const chk = value && i === index
		model.value.methods[i].isChecked = chk

		for (let j = 0; j < model.value.methods[i].meta_datas.length; j++) {
			nextTick(() => {
				if (!model.value) return

				model.value.methods[i].meta_datas[j].isChecked = chk
			})
		}

		nextTick(checkedChange)
	}
}

function onMetaCheckedChange(checked: boolean | undefined, methodIndex: number, metaIndex: number) {
	if (!model.value) return

	for (let i = 0; i < model.value.methods.length; i++) {
		if (methodIndex !== i) {
			for (let j = 0; j < model.value.methods[i].meta_datas.length; j++) {
				nextTick(() => {
					if (model.value) model.value.methods[i].meta_datas[j].isChecked = false
				})
			}
		}
	}
	nextTick(checkedChange)
}

function checkedChange() {
	if (!model.value) return

	const checked: ApplicableMethodMetaData[] = []
	let meth: ApplicableMethodExpand | undefined

	for (let i = 0; i < model.value.methods.length; i++) {
		for (let j = 0; j < model.value.methods[i].meta_datas.length; j++) {
			if (model.value.methods[i].meta_datas[j].isChecked) {
				checked.push(model.value.methods[i].meta_datas[j])
				meth = model.value.methods[i]
			}
		}
	}

	editor.massEdit.method = meth
	editor.massEdit.selected = checked
}

async function massEdit(changes: MassChanges) {
	const request: MassEditRequest = {
		selected: editor.massEdit.selected.map((x) => x.id),
		changes,
		isCustom: editor.massEdit.method?.is_custom ?? false,
	}
	await http.post('api/massEditMethodMetaData', request)
	await editor.refresh()
}

async function fetchMethods(page = 1) {
	const expanded: number[] = []
	for (const e of model.value?.methods ?? []) {
		if (e.isExpanded) expanded.push(e.method_id)
	}

	const urlParams = new URLSearchParams(document.location.search)
	urlParams.set('page', page.toString())

	const { data } = await http.get<Response<ApplicableMethodsResponse>>(`api/applicableMethods?${urlParams.toString()}`)
	if (data.data) model.value = data.data

	if (!model.value) return

	isAllExpanded.value = false

	for (const e of expanded) {
		const method = model.value.methods.find((x) => x.method_id === e)
		if (method) method.isExpanded = true
	}

	for (let i = 0; i < model.value.methods.length; i++) {
		for (let j = 0; j < model.value.methods[i].meta_datas.length; j++) {
			editor.watchMethod(model.value.methods[i].meta_datas[j])
		}
	}
}

function clickMethod(e: MouseEvent, i: number) {
	if (!model.value?.methods.length) return

	const target = e.target as HTMLElement
	if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA' && target.tagName !== 'I') {
		model.value.methods[i].isExpanded = !model.value.methods[i].isExpanded
	}
}

onBeforeMount(async () => {
	await fetchMethods()
	await audit.fetch()
})
</script>

<template>
	<div class="rounded-container p-3">
		<table class="table table-striped">
			<thead>
				<tr>
					<th v-if="showCheckbox"></th>
					<th><PlusButton v-model="isAllExpanded"></PlusButton></th>
					<th>{{ $t('module') }}</th>
					<th>{{ $t('securityNeed') }}</th>
					<th>{{ $t('reference') }}</th>
					<th>{{ $t('title') }}</th>
					<th class="text-center">{{ $t('associations') }}</th>
				</tr>
			</thead>
			<tbody>
				<template v-if="model" v-for="(method, i) in model.methods" :key="method.method_id">
					<tr class="main-row" @click="clickMethod($event, i)">
						<td v-if="showCheckbox">
							<input
								class="form-check-input"
								type="checkbox"
								v-model="model.methods[i].isChecked"
								@change="checkAllMeta(($event.target as any).checked, i)"
							/>
						</td>
						<td><PlusButton v-model="method.isExpanded"></PlusButton></td>
						<td>{{ method.module_name }}</td>
						<td>{{ $t(method.type) }}</td>
						<td>{{ method.reference }}</td>
						<td>{{ method.description }}</td>
						<td class="text-center">
							<span v-if="method.module_type === 'P'">-</span>
							<span v-else>{{ method.meta_datas.length }}</span>
						</td>
					</tr>
					<tr class="group-row" v-if="method.isExpanded">
						<td colspan="9">
							<div>
								<table class="table table-striped">
									<thead>
										<tr class="submethod">
											<th v-if="showCheckbox" style="width: 24px"></th>
											<th v-if="method.meta_datas.length > 1" style="width: 8px"></th>
											<th style="width: 170px">{{ $t('association') }}</th>
											<th style="width: 300px">{{ $t('responsible') }}</th>
											<th style="width: 120px">{{ $t('priority') }}</th>
											<th style="width: 200px">{{ $t('status.label') }}</th>
											<th style="width: 110px">{{ $t('price') }}</th>
											<th style="width: 180px">{{ $t('institution') }}</th>
											<th style="width: 140px">{{ $t('dueDateIn') }}</th>
											<th style="width: auto">{{ $t('comment') }}</th>
										</tr>
									</thead>
									<tbody>
										<MethodMetaRow
											v-for="(mmd, j) in method.meta_datas"
											:key="mmd.id"
											v-model="method.meta_datas[j]"
											:module-type="method.module_type"
											:is-custom="method.is_custom"
											:starts-expanded="method.meta_datas.length === 1"
											@checkedChange="onMetaCheckedChange($event, i, j)"
										></MethodMetaRow>
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
	<Pagination v-if="model?.totalPages ?? 0 > 1" v-model="currentPage" :total-pages="model?.totalPages ?? 0" />
	<div id="methods-pagination" :data-page="currentPage"></div>

	<MassEditMethodDialog v-model="editor.massEdit" @mass-edit="massEdit($event)"></MassEditMethodDialog>
	<EditMethodsToolbar></EditMethodsToolbar>

	<template v-if="editor.isEditMode && (model?.totalPages ?? 0 > 1)">
		<br />
		<br />
		<br />
		<br />
	</template>
</template>
