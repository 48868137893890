import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n, { setI18nLanguage } from '@/i18n'
import { initHttp } from './services/http'
import { initCountedTextareas } from '@/scripts/textarea-counter'

import ApiWidget from '@/components/ApiWidget.vue'
import OverallStatusChart from '@/components/dashboard/OverallStatusChart.vue'
import ProgressChart from '@/components/dashboard/ProgressChart.vue'
import ApproachingDueDate from '@/components/dashboard/ApproachingDueDate.vue'
import DueDateMethods from '@/components/dashboard/DueDateMethods.vue'
import AuditTypeSelect from '@/components/audit/AuditTypeSelect.vue'
import EditMethodsBtn from '@/components/methods/EditMethodsBtn.vue'
import EditMethodsToolbar from '@/components/methods/EditMethodsToolbar.vue'
import MethodTable from '@/components/methods/MethodTable.vue'
import JiraExport from '@/components/jira/JiraExport.vue'
import SecurityMethod from '@/components/business_processes/SecurityMethod.vue'
import MassEditMethodsBtn from '@/components/methods/MassEditMethodsBtn.vue'
import InstanceAutocomplete from '@/components/widgets/InstanceAutocomplete.vue'
import Datepicker from '@/components/widgets/Datepicker.vue'
import Slider from '@/components/widgets/Slider.vue'
import Toaster from '@/components/widgets/Toaster.vue'
import AuditIcon from '@/components/icons/AuditIcon.vue'

import * as style from '@/styles/main.sass'
import * as bootstrap from 'bootstrap'
import { session } from './session'
import toastStore from './stores/toast'

addEventListener = function (a, b, c) {
	if (a instanceof HTMLElement) EventTarget.prototype.addEventListener.call(a, b, c)
	else Window.prototype.addEventListener.call(window, a, b, c)
}
removeEventListener = function (a, b, c) {
	if (a instanceof HTMLElement) EventTarget.prototype.removeEventListener.call(a, b, c)
	else Window.prototype.removeEventListener.call(window, a, b, c)
}

const origHtml = $.fn.html
$.fn.html = function (value) {
	const ret = origHtml.apply(this, [value])
	for (const el of this) {
		let app = el['__cybsis_vue']
		if (app) app.unmount()

		app = createVue()
		el['__cybsis_vue'] = app
		app.mount(el)
	}
	return ret
}

// Shared state between components
const pinia = createPinia()

const createVue = () =>
	createApp({})
		.use(pinia)
		.use(i18n)

		// Component registration (only required for global availability)
		.component('apiWidget', ApiWidget)
		.component('dashboardOverallStatusChart', OverallStatusChart)
		.component('dashboardProgressChart', ProgressChart)
		.component('dashboardApproachingDueDate', ApproachingDueDate)
		.component('dashboardDueDateMethods', DueDateMethods)
		.component('auditTypeSelect', AuditTypeSelect)
		.component('editMethodsBtn', EditMethodsBtn)
		.component('editMethodsToolbar', EditMethodsToolbar)
		.component('massEditMethodsBtn', MassEditMethodsBtn)
		.component('methodTable', MethodTable)
		.component('jiraExport', JiraExport)
		.component('securityMethod', SecurityMethod)
		.component('instanceAutocomplete', InstanceAutocomplete)
		.component('datepicker', Datepicker)
		.component('slider', Slider)
		.component('toaster', Toaster)
		.component('auditIcon', AuditIcon)
window.bootstrap = bootstrap

// Mount main instance
document.addEventListener('DOMContentLoaded', () => {
	const data = window.sessionMap
	setI18nLanguage(data.language)
	createVue().mount('app')

	const toaster = toastStore()
	window.legacyShowToast = function (type, text) {
		if (type == 'error') toaster.showError(text)
		else toaster.showSuccess(text)
	}

	initHttp()

	window.legacyReady()
	initCountedTextareas()
})
