<script setup lang="tsx">
import PlusButton from '@/components/widgets/PlusButton.vue'
import type { ApplicableMethodMetaData } from '@/models/api-generated'
import { getMethodStatusText, formatDate } from '@/services/utils'
import methodEditor from '@/stores/method-editor'
import auditStore from '@/stores/audit'
import AuditComments from '@/components/audit/AuditComments.vue'
import Datepicker from '@/components/widgets/Datepicker.vue'
import PrioritySelect from '@/components/widgets/PrioritySelect.vue'
import UserAutocomplete from '@/components/widgets/UserAutocomplete.vue'
import Rules from '@/components/rules/Rules.vue'
import NumericInput from '@/components/widgets/NumericInput.vue'
import { computed, onBeforeMount } from 'vue'
import {
	ConditionallyImplemented,
	Implemented,
	NeedsAttention,
	NotImplemented,
	PartiallyImplemented,
	WillNotApply,
	WillNotImplement,
} from '@/models/enums-generated'

const props = defineProps<{
	moduleType: string
	isCustom: boolean
	startsExpanded: boolean
}>()

const model = defineModel<ApplicableMethodMetaData>({ required: true })
const editor = methodEditor()
const audit = auditStore()
const showCheckbox = computed(() => !(editor.isEditMode || audit.isAudit))

const emit = defineEmits(['checkedChange'])

const getFloat = (price: number): string => {
	return price.toFixed(2)
}

const daysUntilDate = (targetDate: string): number => {
	const [day, month, year] = targetDate.split('.').map(Number)
	const target = new Date(year, month - 1, day)
	const today = new Date()
	today.setHours(0, 0, 0, 0)
	const differenceInTime = target.getTime() - today.getTime()
	const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))

	let daysUntil = 0
	let daysPassed = 0
	if (differenceInDays > 0) {
		daysUntil = differenceInDays
		return daysUntil
	} else {
		daysPassed = differenceInDays
		return daysPassed
	}
}

function clickMeta(e: MouseEvent) {
	const target = e.target as HTMLElement
	if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA' && target.tagName !== 'I') {
		model.value.isExpanded = !model.value.isExpanded
	}
}

onBeforeMount(() => {
	model.value.isExpanded = props.startsExpanded
})
</script>

<template>
	<tr class="submethod" @click="clickMeta($event)">
		<td v-if="showCheckbox">
			<input
				class="form-check-input"
				type="checkbox"
				v-model="model.isChecked"
				@change="emit('checkedChange', ($event.target as any).checked)"
			/>
		</td>
		<td class="text-center" v-if="!props.startsExpanded"><PlusButton v-model="model.isExpanded"></PlusButton></td>
		<td style="max-width: 16em" :title="model.asset.name">
			<span v-if="props.moduleType === 'P'">{{ $t('allBusinessProcesses') }}</span>
			<span v-else class="badge bg-primary me-2">A</span><span>{{ model.asset.name }}</span>
		</td>
		<td>
			<template v-if="!editor.isEditMode">
				<span v-if="!model.responsible">-</span>
				<span v-else class="me-2">
					<img src="@/assets/images/avatar.svg" alt="responsible" class="image" />
					{{ model.responsible.firstname }} {{ model.responsible.lastname }}
				</span>
			</template>
			<template v-else>
				<UserAutocomplete v-model="model.responsible"></UserAutocomplete>
			</template>
		</td>
		<td>
			<template v-if="!editor.isEditMode">
				<span v-if="model.priority">{{ $t(model.priority) }}</span>
				<span v-else>-</span>
			</template>
			<template v-else>
				<PrioritySelect v-model="model.priority"></PrioritySelect>
			</template>
		</td>
		<td class="status-cell">
			<i v-if="model.status === NotImplemented" class="fas fa-circle me-2 ms-2 pie3"></i>
			<i v-else-if="model.status === PartiallyImplemented" class="fas fa-circle me-2 ms-2 pie2"></i>
			<i v-else-if="model.status === Implemented" class="fas fa-circle me-2 ms-2 pie1"></i>
			<i
				v-else-if="model.status === ConditionallyImplemented"
				class="far fa-solid fa-circle-exclamation me-2 ms-2 pie1"
				:title="$t('status.hover.conditionallyImplemented')"
			></i>
			<i v-else-if="model.status === WillNotApply" class="fas fa-circle me-2 ms-2 pie4"></i>
			<i v-else-if="model.status === WillNotImplement" class="fas fa-circle me-2 ms-2 pie4"></i>
			<i v-else-if="model.status === NeedsAttention" class="fas fa-circle me-2 ms-2 pie6"></i>
			<span>{{ getMethodStatusText(model.status) }}</span>
		</td>
		<td>
			<template v-if="!editor.isEditMode">{{ getFloat(model.price) }}</template>
			<NumericInput v-else class="form-control form-control-sm" v-model="model.price"></NumericInput>
		</td>
		<td>{{ model.instance.name }}</td>
		<td>
			<template v-if="!editor.isEditMode">
				<span v-if="model.status === Implemented">-</span>
				<span v-else>
					<span v-if="model.due_date !== ''">
						<span v-if="daysUntilDate(model.due_date) < 0">
							<span class="fw-bold" style="color: red">{{ $t('overdue') }}</span>
						</span>
						<span v-else-if="daysUntilDate(model.due_date) === 1">
							<span class="fw-bold" style="color: red">{{ daysUntilDate(model.due_date) }} {{ $t('day') }}</span>
						</span>
						<span v-else-if="daysUntilDate(model.due_date) > 1 && daysUntilDate(model.due_date) < 3">
							<span class="fw-bold" style="color: red">{{ daysUntilDate(model.due_date) }} {{ $t('days') }}</span>
						</span>
						<span v-else-if="daysUntilDate(model.due_date) > 2 && daysUntilDate(model.due_date) < 6">
							<span style="color: rgb(205, 135, 5)">{{ daysUntilDate(model.due_date) }} {{ $t('days') }}</span>
						</span>
						<span v-else>
							<span style="color: green">{{ daysUntilDate(model.due_date) }} {{ $t('days') }}</span>
						</span>
					</span>
					<span v-else>-</span>
				</span>
			</template>
			<template v-else>
				<Datepicker v-model:local="model.due_date"></Datepicker>
			</template>
		</td>
		<td style="max-width: 14em">
			<template v-if="!editor.isEditMode">
				<span :title="model.comment">{{ model.comment }}</span>
			</template>
			<template v-else>
				<textarea maxlength="500" v-model="model.comment"></textarea>
			</template>
		</td>
	</tr>
	<tr v-if="model.isExpanded" class="group-row">
		<td :colspan="audit.isAudit ? 5 : 10">
			<Rules :isCustom="props.isCustom" v-model="model"></Rules>
		</td>

		<template v-if="audit.isAudit">
			<td colspan="5" class="ps-2">
				<div class="row m-0 mt-2">
					<div class="col m-0" v-if="audit.activeInternal">
						<div class="mt-3 text-center fw-bold">{{ $t('internalComments') }}</div>
						<AuditComments v-model="audit.activeInternal" :methodMetadataID="model.id"></AuditComments>
					</div>

					<div class="col m-0" v-if="audit.activeBasic">
						<div class="mt-3 text-center fw-bold">{{ $t('auditComments') }}</div>
						<AuditComments v-model="audit.activeBasic" :methodMetadataID="model.id"></AuditComments>
					</div>
				</div>

				<div class="row m-0 mt-2" v-if="audit.oldInternal.length + audit.oldBasic.length">
					<div class="col m-0" v-if="audit.oldInternal.length">
						<div class="mt-3 text-center fw-bold">{{ $t('oldInternalAudits') }}</div>
						<select class="form-select mx-2" v-model="audit.oldInternalSelected">
							<option v-for="a in audit.oldInternal" :value="a">{{ formatDate(a.createdAt) }} - {{ a.name }}</option>
						</select>
						<AuditComments
							v-if="audit.oldInternalSelected"
							v-model="audit.oldInternalSelected"
							:methodMetadataID="model.id"
						></AuditComments>
					</div>

					<div class="col m-0" v-if="audit.oldBasic.length">
						<div class="mt-3 text-center fw-bold">{{ $t('oldAudits') }}</div>
						<select class="form-select mx-2" v-model="audit.oldBasicSelected">
							<option v-for="a in audit.oldBasic" :value="a">{{ formatDate(a.createdAt) }} - {{ a.name }}</option>
						</select>
						<AuditComments v-if="audit.oldBasicSelected" v-model="audit.oldBasicSelected" :methodMetadataID="model.id"></AuditComments>
					</div>
				</div>
			</td>
		</template>
	</tr>
</template>

<style lang="sass" scoped>
tr.submethod > td
		cursor: pointer
		white-space: nowrap

tr.group-row > td
	cursor: default

td
	overflow: hidden
	text-overflow: ellipsis
</style>
