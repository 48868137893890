<script setup lang="tsx">
import type { Instance } from '@/models/api-generated'
import { nextTick, ref, watch } from 'vue'
import Autocomplete from 'bootstrap5-autocomplete'

const props = defineProps({
	id: String,
	name: String,
	disabled: Boolean,
})
const model = defineModel<Instance>()
const elementRef = ref<HTMLInputElement>()
let instance: Autocomplete | undefined

watch(model, (m) => {
	nextTick(() => {
		if (instance?._searchInput) instance._searchInput.value = m?.name ?? ''
	})
})

watch(elementRef, (el) => {
	if (el) {
		if (instance) instance.dispose()

		instance = new Autocomplete(el, {
			queryParam: 'q',
			//searchFields: ['name'],
			labelField: 'name',
			valueField: 'id',
			server: 'api/instances',
			serverDataKey: 'data',
			liveServer: true,
			//hiddenInput: true,
			//hiddenValue: model.value?.id.toString() ?? '',
			//updateOnSelect: true,
			suggestionsThreshold: 0,
			onSelectItem: (item: Instance, inst: Autocomplete) => {
				model.value = item
			},
			onChange: (item: Instance | undefined, inst: Autocomplete) => {
				model.value = item
			},
		})

		nextTick(() => {
			if (instance?._searchInput) instance._searchInput.value = model.value?.name ?? ''
		})
	}
})
</script>

<template>
	<input :id="props.id" :disabled="props.disabled" ref="elementRef" type="search" class="form-control" :value.once="model?.id" />

	<input type="hidden" :name="props.name" :value="model?.id" />
</template>

<style></style>
