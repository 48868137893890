<script setup lang="tsx">
import { onBeforeMount, ref, watch } from 'vue'

const props = defineProps({
	id: String,
	disabled: Boolean,
	name: String,
})
const local = defineModel<String>('local')
const iso = defineModel<String>('iso')

watch(local, toIso)
watch(iso, toLocal)

function toLocal() {
	if (iso.value) {
		const [year, month, day] = iso.value.split('-')
		local.value = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
	} else if (local.value) {
		local.value = undefined
	}
}

function toIso() {
	if (local.value) {
		const [day, month, year] = local.value.split('.')
		iso.value = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
	} else if (iso.value) {
		iso.value = undefined
	}
}

onBeforeMount(() => {
	if (local.value) toIso()
	else toLocal()
})
</script>

<template>
	<input :id="props.id" :name="props.name" :disabled="props.disabled" type="date" class="form-control" v-model="iso" />
</template>
