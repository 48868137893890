<script setup lang="tsx">
import store from '@/stores/slider'
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue'

const slider = store()

const cls = computed(() => {
	return {
		open: slider.isVisible,
	}
})

onBeforeMount(() => {
	document.addEventListener('click', (e) => {
		if (slider.isVisible) {
			const el = e.target as HTMLElement
			const inside = el.closest('.slider')
			if (!inside) nextTick(() => (slider.isVisible = false))
		}
	})
})
</script>

<template>
	<div class="slider" :class="cls">
		<div class="w-100 p-3 d-inline-flex justify-content-between header">
			<h2>{{ slider.title }}</h2>
			<button class="btn btn-default rounded-circle align-self-center" @click="slider.isVisible = false">
				<i class="fas fa-times"></i>
			</button>
		</div>
		<div class="w-100 h-100 p-3 content">
			<component :is="slider.getComponent()"></component>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.slider
    z-index: 10
    padding: 0
    position: fixed
    right: 0
    width: 0
    height: 100vh
    background-color: white
    border-left: 1px solid #dadada
    transition: width .2s ease-in-out

.open
    width: 35em

.header
    border-bottom: 1px solid #dadada
    background-color: #f8f8f8

.header > h2
    margin: 0

.content
    overflow-y: scroll
</style>
