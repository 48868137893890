<script setup lang="tsx">
import PlusButton from '@/components/widgets/PlusButton.vue'
import type { CustomRuleMetaData, StandardRuleMetaData } from '@/models/api-generated'
import methodEditor from '@/stores/method-editor'
import { computed, onBeforeMount, ref, watch } from 'vue'
import RuleStatusIcon from '@/components/widgets/RuleStatusIcon.vue'
import RuleStatusSelect from '@/components/widgets/RuleStatusSelect.vue'
import SubRuleRow from './SubRuleRow.vue'
import { type RuleStatus } from '@/models/enums-generated'
import { calculateParentRuleStatus, isCommentRequired } from '@/services/utils'

const props = defineProps({
	isReadOnly: Boolean,
})
const model = defineModel<StandardRuleMetaData | CustomRuleMetaData>({ required: true })
const isExpanded = ref(false)
const editor = methodEditor()
const isEditMode = computed(() => !props.isReadOnly && editor.isEditMode)
editor.watchRule(model.value)

const isParentChangeEvent = ref(false)

const hasSubrules = computed(() => model.value.sub_rules.length > 0)

onBeforeMount(async () => {
	if (hasSubrules) {
		isExpanded.value = true

		watch(
			() => model.value.sub_rules.map((subrule) => subrule.status),
			(newStatuses) => {
				setTimeout(() => {
					if (!isParentChangeEvent.value) {
						model.value.status = calculateParentRuleStatus(newStatuses)
					}

					isParentChangeEvent.value = false
				}, 0)
			},
		)

		watch(
			() => model.value.status,
			(status: RuleStatus) => {
				setTimeout(() => {
					if (isParentChangeEvent.value) {
						model.value.sub_rules.forEach((subrule) => {
							subrule.status = status
						})
					}
				}, 0)
			},
		)
	}
})
</script>

<template>
	<div class="row">
		<div class="col d-inline-flex align-items-center">
			<div v-if="hasSubrules">
				<PlusButton v-model="isExpanded"></PlusButton>
			</div>
			<div v-else>
				<i class="far fa-plus-square table-cell-icon" style="color: lightgray"></i>
			</div>
			<div>
				{{ model.rule.value }}
			</div>
		</div>

		<div class="col-3 align-self-center text-center">
			<RuleStatusIcon v-if="!isEditMode" v-model="model.status"></RuleStatusIcon>
			<RuleStatusSelect
				v-else
				v-model="model.status"
				v-model:isParentChangeEvent="isParentChangeEvent"
				:has-subrules="hasSubrules"
				class="d-inline"
			></RuleStatusSelect>
		</div>
	</div>
	<div v-if="model.comment || isEditMode" class="row">
		<div class="col">
			<label :for="'method-comment' + model.id" class="form-label">{{ $t('comment') }}:</label>
			<div v-if="!isEditMode" class="comment m-0" rows="1">{{ model.comment }}</div>
			<textarea
				v-else
				:id="'method-comment' + model.id"
				class="form-control"
				:class="{ 'is-invalid': isCommentRequired(model.status, model.comment) }"
				maxlength="500"
				v-model="model.comment"
			></textarea>
		</div>
	</div>
	<SubRuleRow v-if="isExpanded" v-model="model" :is-read-only="props.isReadOnly"></SubRuleRow>
</template>

<style lang="sass" scoped>
.comment
	border-radius: .25rem
	background-color: rgb(248, 248, 248)
	padding: .5rem
</style>
