<script lang="tsx" setup>
import Modal from '../shared/Modal.vue'
import { computed, ref, watch } from 'vue'
import type {
	Response,
	ApplicableMethodInfo,
	StandardMethodMetaData,
	CustomMethodMetaData,
	MassChanges,
	MassEditRule,
} from '@/models/api-generated'
import { type MethodEditModel } from '@/stores/method-editor'
import http from '@/services/http'
import { RuleImplemented, RuleNotImplemented, RuleWillNotApply, type RuleStatus } from '@/models/enums-generated'
import UserAutocomplete from '@/components/widgets/UserAutocomplete.vue'
import PrioritySelect from '@/components/widgets/PrioritySelect.vue'
import RuleStatusSelect from '@/components/widgets/RuleStatusSelect.vue'
import Datepicker from '../widgets/Datepicker.vue'
import { PromptForRequiredComment } from './required-comment'

const emit = defineEmits(['massEdit'])
const model = defineModel<MethodEditModel>({ required: true })
const info = ref<ApplicableMethodInfo<StandardMethodMetaData | CustomMethodMetaData>>()
const changes = ref<MassChanges>({
	responsible: { enabled: true },
	priority: { enabled: true, value: '' },
	price: { enabled: true, value: 0 },
	dueDate: { enabled: true },
	comment: { enabled: true, value: '' },
	rules: {},
})
const canSave = computed(() => {
	for (const a of Object.values(changes.value.rules)) {
		if (a.status.enabled && a.status.value === RuleWillNotApply) {
			if (!a.comment.enabled || !a.comment.value) return false
		}
	}

	return true
})

let currentMeta = 0

function commentClass(rule: MassEditRule) {
	return {
		'is-invalid': rule.status.enabled && rule.status.value === RuleWillNotApply && (!rule.comment.enabled || !rule.comment.value),
	}
}

watch(
	() => model.value.visible,
	async (newValue) => {
		if (!newValue || !model.value.selected.length) return

		const meta = model.value.selected[0]

		if (currentMeta === meta.id) return
		currentMeta = meta.id

		const { data } = await http.get<Response<ApplicableMethodInfo<any>>>(
			`/api/applicableMethods/${currentMeta}/${model.value.method?.is_custom}`,
		)
		if (data.data) info.value = data.data

		for (const k of Object.keys(changes.value.rules)) delete changes.value.rules[k]

		for (const a of info.value?.meta?.rules ?? []) {
			changes.value.rules[a.rule.id] = {
				status: { enabled: true, value: RuleNotImplemented },
				comment: { enabled: true, value: '' },
			}

			for (const b of a.sub_rules) {
				changes.value.rules[b.rule.id] = {
					status: { enabled: true, value: RuleNotImplemented },
					comment: { enabled: true, value: '' },
				}
			}
		}
	},
)

function checkAllMethods(checked: boolean) {
	changes.value.responsible.enabled = checked
	changes.value.priority.enabled = checked
	changes.value.price.enabled = checked
	changes.value.dueDate.enabled = checked
	changes.value.comment.enabled = checked
}

function checkAllRuleStatuses(checked: boolean) {
	for (const a of Object.values(changes.value.rules)) {
		a.status.enabled = checked
	}
}

function checkAllRuleComments(checked: boolean) {
	for (const a of Object.values(changes.value.rules)) {
		a.comment.enabled = checked
	}
}

function setRuleStatus(status: RuleStatus) {
	let cmt: string | null | undefined
	if (status == RuleWillNotApply) {
		cmt = PromptForRequiredComment()
		changes.value.comment.value = cmt
	}

	for (const a of Object.values(changes.value.rules)) {
		a.status.enabled = true
		a.status.value = status

		if (cmt) {
			a.comment.enabled = true
			a.comment.value = cmt
		}
	}
}

function apply() {
	model.value.visible = false
	emit('massEdit', changes.value)
}
</script>

<template>
	<Modal modal-class="modal-lg" v-model="model.visible">
		<template #header>
			<div class="row">
				<div class="ellipsis col-11">
					{{ model.method?.reference }}
					-
					{{ model.method?.description }}
				</div>
				<div class="col-1">({{ model.selected.length }})</div>
			</div>
		</template>
		<template #body>
			<template v-if="model && model.method && info && info.meta">
				<ul class="nav nav-tabs justify-content-between" role="tablist">
					<li class="nav-item col-5" role="presentation">
						<button
							class="nav-link w-100 active"
							data-bs-toggle="tab"
							data-bs-target="#masseditmethod-method"
							type="button"
							role="tab"
							aria-controls="home"
							aria-selected="true"
						>
							{{ $t('methods') }}
						</button>
					</li>
					<li class="col-2"></li>
					<li class="nav-item col-5" role="presentation">
						<button
							class="nav-link w-100"
							data-bs-toggle="tab"
							data-bs-target="#masseditmethod-rules"
							type="button"
							role="tab"
							aria-controls="profile"
							aria-selected="false"
						>
							{{ $t('rules') }}
						</button>
					</li>
				</ul>

				<!-- Tab panes -->
				<div class="tab-content mt-4">
					<div class="tab-pane active" id="masseditmethod-method" role="tabpanel">
						<div class="container">
							<div class="row mb-3">
								<div class="col-12">
									{{ $t('massEditWarning') }}
								</div>
							</div>
							<div class="row mb-3">
								<label class="col-3 col-form-label"></label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input
												class="form-check-input"
												type="checkbox"
												@click="checkAllMethods(($event.target as any).checked)"
												:checked="true"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="masseditmethod-responsible" class="col-3 col-form-label">{{ $t('responsible') }}</label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input class="form-check-input" type="checkbox" v-model="changes.responsible.enabled" />
										</div>
										<UserAutocomplete
											id="masseditmethod-responsible"
											:disabled="!changes.responsible.enabled"
											v-model="changes.responsible.value"
										></UserAutocomplete>
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="masseditmethod-priority" class="col-3 col-form-label">{{ $t('priority') }}</label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input class="form-check-input" type="checkbox" v-model="changes.priority.enabled" />
										</div>
										<PrioritySelect
											id="masseditmethod-priority"
											:disabled="!changes.priority.enabled"
											v-model="changes.priority.value"
										></PrioritySelect>
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="masseditmethod-price" class="col-3 col-form-label">{{ $t('price') }}</label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input class="form-check-input" type="checkbox" v-model="changes.price.enabled" />
										</div>
										<input
											type="number"
											class="form-control"
											id="masseditmethod-price"
											:disabled="!changes.price.enabled"
											v-model="changes.price.value"
										/>
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="masseditmethod-duedate" class="col-3 col-form-label">{{ $t('dueDate') }}</label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input class="form-check-input" type="checkbox" v-model="changes.dueDate.enabled" />
										</div>
										<Datepicker
											id="masseditmethod-duedate"
											:disabled="!changes.dueDate.enabled"
											v-model:local="changes.dueDate.value"
										></Datepicker>
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="masseditmethod-comment" class="col-3 col-form-label">{{ $t('comment') }}</label>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input class="form-check-input" type="checkbox" v-model="changes.comment.enabled" />
										</div>
										<textarea
											class="form-control"
											id="masseditmethod-comment"
											:disabled="!changes.comment.enabled"
											v-model="changes.comment.value"
											maxlength="500"
										></textarea>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-12">
									<button class="btn btn-danger me-2" @click="setRuleStatus(RuleWillNotApply)">{{ $t('methodWillNotApply') }}</button>
									<button class="btn btn-success" @click="setRuleStatus(RuleImplemented)">{{ $t('methodImplemented') }}</button>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane" id="masseditmethod-rules" role="tabpanel">
						<div class="container">
							<div class="row mb-3">
								<div class="col-2"></div>
								<div class="col-6">
									<div class="input-group">
										<div class="input-group-text">
											<input
												class="form-check-input"
												type="checkbox"
												@click="checkAllRuleComments(($event.target as any).checked)"
												:checked="true"
											/>
										</div>
									</div>
								</div>
								<div class="col">
									<div class="input-group">
										<div class="input-group-text">
											<input
												class="form-check-input"
												type="checkbox"
												@click="checkAllRuleStatuses(($event.target as any).checked)"
												:checked="true"
											/>
										</div>
									</div>
								</div>
							</div>

							<template v-for="(a, i) in info.meta.rules">
								<div class="row align-items-center mb-4" v-if="changes.rules[a.rule.id]">
									<div class="col-8">
										<div class="row mb-3">
											<div class="col-12">{{ a.rule.value }}</div>
										</div>
										<div class="row">
											<label :for="'masseditmethod-comment' + a.id" class="col-3 col-form-label">{{ $t('comment') }}</label>
											<div class="col">
												<div class="input-group">
													<div class="input-group-text">
														<input class="form-check-input" type="checkbox" v-model="changes.rules[a.rule.id].comment.enabled" />
													</div>
													<textarea
														class="form-control"
														:disabled="!changes.rules[a.rule.id].comment.enabled"
														:id="'masseditmethod-comment' + a.id"
														:class="commentClass(changes.rules[a.rule.id])"
														v-model="changes.rules[a.rule.id].comment.value"
														maxlength="500"
													></textarea>
												</div>
											</div>
										</div>
									</div>
									<div class="col-4">
										<div class="input-group">
											<div class="input-group-text">
												<input class="form-check-input" type="checkbox" v-model="changes.rules[a.rule.id].status.enabled" />
											</div>
											<RuleStatusSelect
												:disabled="!changes.rules[a.rule.id].status.enabled"
												v-model="changes.rules[a.rule.id].status.value"
											></RuleStatusSelect>
										</div>
									</div>
								</div>

								<div class="subrules" v-if="a.sub_rules.length">
									<template v-for="(b, j) in a.sub_rules">
										<div class="row align-items-center mb-4">
											<div class="col-8">
												<div class="row mb-3">
													<div class="col-12">{{ b.rule.value }}</div>
												</div>
												<div class="row">
													<label :for="'masseditmethod-comment' + b.id" class="col-3 col-form-label">{{ $t('comment') }}</label>
													<div class="col">
														<div class="input-group">
															<div class="input-group-text">
																<input class="form-check-input" type="checkbox" v-model="changes.rules[b.rule.id].comment.enabled" />
															</div>
															<textarea
																class="form-control"
																:disabled="!changes.rules[b.rule.id].comment.enabled"
																:id="'masseditmethod-comment' + b.id"
																:class="commentClass(changes.rules[b.rule.id])"
																v-model="changes.rules[b.rule.id].comment.value"
																maxlength="500"
															></textarea>
														</div>
													</div>
												</div>
											</div>
											<div class="col-4">
												<div class="input-group">
													<div class="input-group-text">
														<input class="form-check-input" type="checkbox" v-model="changes.rules[b.rule.id].status.enabled" />
													</div>
													<RuleStatusSelect
														:disabled="!changes.rules[b.rule.id].status.enabled"
														v-model="changes.rules[b.rule.id].status.value"
													></RuleStatusSelect>
												</div>
											</div>
										</div>
										<hr v-if="j < a.sub_rules.length - 1" />
									</template>
								</div>

								<hr v-if="i < info.meta.rules.length - 1" />
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template #footer>
			<div class="w-100 d-inline-flex justify-content-between text-nowrap">
				<button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
				<button type="button" class="btn btn-primary" @click="apply()" :disabled="!canSave">{{ $t('save') }}</button>
			</div>
		</template>
	</Modal>
</template>

<style lang="sass" scoped>
.ellipsis
	overflow: hidden
	text-overflow: ellipsis

.subrules
	background-color: #f8f8f8
	padding: 1em
	margin-left: 1em
</style>
